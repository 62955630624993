export interface IAccountSearchConfig {
    userType: number,
    label: string
}
export const ACCOUNT_TYPE: IAccountSearchConfig[] = [
    {
        userType: 0,
        label: '游客'
    },
    {
        userType: 1,
        label: 'FaceBook'
    },
    {
        userType: 2,
        label: 'Google'
    },
    {
        userType: 3,
        label: 'GameCenter'
    },
    {
        userType: 4,
        label: 'Wechat'
    },
    {
        userType: 5,
        label: 'Twitter'
    },
    {
        userType: 6,
        label: '英雄账号'
    },
    {
        userType: 7,
        label: 'Apple'
    },
    {
        userType: 8,
        label: 'Line'
    },
    {
        userType: 9,
        label: '外部账号'
    },
    {
        userType: 10,
        label: '华为'
    },
    {
        userType: 11,
        label: 'OPPO'
    },
    {
        userType: 16,
        label: 'EMAIL'
    },
];

